import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
    const location = useLocation();

    const handleScrollToTop = () => {
        if (location.pathname === "/") {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    return (
        <header className="navbar">
            <div className="navbar-container">
                <div className="logo-placeholder">
                    <Link to="/" onClick={handleScrollToTop}>
                        <img src="/Logo.jpeg" alt="Company Logo" className="logo-image" />
                    </Link>
                </div>
                <nav className="nav-links">
                    <ul>
                        <li>
                            <Link
                                to="/"
                                className={`nav-link ${location.pathname === '/' ? 'active-link' : ''}`}
                                onClick={handleScrollToTop}
                            >
                                Home
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/about"
                                className={`nav-link ${location.pathname === '/about' ? 'active-link' : ''}`}
                            >
                                Über Uns
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/contact"
                                className={`nav-link ${location.pathname === '/contact' ? 'active-link' : ''}`}
                            >
                                Kontakt
                            </Link>
                        </li>
                    </ul>
                </nav>
                <div className="contact-info1">
                <img src="/qrcode.png" alt="Company Logo" className="qrcode" />
                    <p className="phone-number">+43 677 634 72758</p>
                </div>
            </div>
        </header>
    );
}

export default Navbar;
