import React from 'react';
import './About.css';

function About() {
    return (
        <div className="about">
            <div className="hero-image">
                <img src="/About.jpg" alt="About us Gelbstein Elektrotechnik"/>
                <div className="hero-text">
                    <h1>Über uns</h1>
                </div>
            </div>
            <div className="main-content">
            <h1>Gelbstein Elektrotechnik</h1>
            </div>
            <p>Unser Unternehmen wurde im Oktober 2023 gegründet und hat sich seither erfolgreich auf dem Markt etabliert. Wir sind stolz darauf, unseren Kunden hochwertige Produkte und Dienstleistungen anzubieten, die gezielt auf ihre individuellen Bedürfnisse zugeschnitten sind.</p>
            <p>Unser Team setzt sich aus erfahrenen Experten zusammen, die über tiefgehendes Branchenwissen verfügen und sich kontinuierlich darum bemühen, innovative Lösungen zu entwickeln. Kundenzufriedenheit hat für uns höchste Priorität, und wir streben danach, langfristige Partnerschaften mit unseren Kunden aufzubauen.</p>
            <p>Durch fortlaufende Weiterentwicklung und Investitionen in moderne Technologien können wir unseren Kunden jederzeit die neuesten und effizientesten Lösungen bereitstellen.</p>
            <p>Wir freuen uns darauf, auch in Zukunft gemeinsam mit unseren Kunden erfolgreich zu sein und sie bei ihren Herausforderungen zu unterstützen.</p>

            <hr className="blue-dash"/>

            <div className="main-content">
                <h1>Warum Sie sich für Gelbstein Elektrotechnik entscheiden sollten</h1>
            </div>
            <p>Willkommen bei Gelbstein Elektrotechnik – Ihrem Experten für professionelle Elektrotechnik-Planung. Wir bieten individuelle Lösungen für die Planung, Installation und Wartung elektrischer Anlagen in Industrie-, Gewerbe- und Privatgebäuden.</p>
            <p>Unsere erfahrenen Elektroingenieure und Techniker arbeiten eng mit unseren Kunden zusammen, um maßgeschneiderte Konzepte zu entwickeln, die höchste Qualitätsstandards erfüllen und effizient umgesetzt werden.</p>
            <p>Von der Erstellung detaillierter Schaltpläne bis zur Inbetriebnahme stehen wir Ihnen mit Fachwissen und Engagement zur Seite. Verlassen Sie sich auf unsere Kompetenz und Zuverlässigkeit für Ihre elektrotechnischen Projekte. Kontaktieren Sie uns noch heute, um mehr über unsere Dienstleistungen zu erfahren. </p>
        </div>
    );
}

export default About;
