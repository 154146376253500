import React from 'react';
import './Header.css';

function Header() {
    return (
        <header className="header">
            <h2>Dienstleistungen</h2>
            <hr class="solid"></hr>
        </header>
    );
}

export default Header;
