import React from 'react';
import './Contact.css';
import ContactForm from "./ContactForm";

function Contact() {
    return (
        <div className="contact-container">
            <div className="contact-info">
                <h2>Kontaktmöglichkeiten</h2>
                <div className="contact-details">
                    <div className="contact-detail">
                        <h3>E-Mail</h3>
                        <p>office@gelbstein-elektrotechnik.at</p>
                    </div>
                    <div className="contact-detail">
                        <h3>Telefon</h3>
                        <p>+43 677 634 72758</p>
                    </div>
                    <div className="contact-detail">
                        <h3>Firmensitz</h3>
                        <p>Musterstraße 1, 1010 Wien</p>
                    </div>
                </div>
            </div>
            <ContactForm />
        </div>
    );
}

export default Contact;
