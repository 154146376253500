import React, { useState } from 'react';
import Slider from 'react-slick';
import './Slideshow.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Slideshow = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 10000,
        beforeChange: (oldIndex, newIndex) => {
            setCurrentSlide(newIndex); // Update the current slide index
        }
    };

    const slideTexts = [
        {
            header: "Effiziente Lösungen für Ihren Energiebedarf",
            text: "Gelbstein Elektrotechnik deckt Ihren Energiebedarf effizient und ressourcenschonend. Mit moderner Technologie bieten wir zuverlässige, leistungsstarke Systeme, die Energiekosten senken und die Umwelt schonen. Aus Wien für Wien, setzten Sie auf nachhalitge Energieversorung mit Gelbstein Elektrotechnik."
        },
        {
            header: "Sicherheit durch Fachkompetenz",
            text: "Mit unserer Elektrotechnik-Expertise sichern wir Ihre Anlagen optimal. Präzise Planung, professionelle Installation und regelmäßige Wartung minimieren Risiken und gewährleisten einen zuverlässigen Betrieb. Vertrauen Sie auf Gelbstein Elektrotechnik für höchste Sicherheit."
        },
        {
            header: "Intelligente Gebäudesteuerung",
            text: "Unsere Lösungen für intelligente Gebäudesteuerung ermöglichen die zentrale und einfache Bedienung aller Funktionen, von Beleuchtung bis Sicherheitstechnik. So sparen Sie Energie, erhöhen den Komfort und optimieren den Betrieb Ihrer Immobilie. Setzen Sie auf smarte Gebäudetechnik mit Gelbstein Elektrotechnik."
        },
        // Add more descriptions as needed
    ];

    return (
        <div className="slideshow-container">
            <div className="text-box">
                <h3>{slideTexts[currentSlide].header}</h3>
                <p>{slideTexts[currentSlide].text}</p>
            </div>
            <div className="slideshow">
                <Slider {...settings}>
                    <div className="slide">
                        <img src="/Energiebedarf.png" alt="Energiebedarf Wien" />
                        <div className="slide-content">
                        </div>
                    </div>
                    <div className="slide">
                        <img src="/Schaltkasten.jpg" alt="Schaltkasten Elektrotechnik" />
                        <div className="slide-content">
                        </div>
                    </div>
                    <div className="slide">
                        <img src="/SmartHome2.jpg" alt="Smart Home Wien" />
                        <div className="slide-content">
                        </div>
                    </div>
                </Slider>
            </div>
        </div>
    );
};

export default Slideshow;
