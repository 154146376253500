import React from 'react';
import './ServiceCards.css';

function ServiceCards() {
    const services = [
        { title: 'Elektrotechnik', description: 'Wir stehen Ihnen zur Verfügung, um Ihnen die best möglichen Lösungen für Ihre elektrischen Anforderungen zu bieten.', image: '/Elektrotechnik.jpg' },
        { title: 'Netzwerktechnik', description: 'Durch das Fachwissen unserer Mitarbeiter, helfen wir mit der Optimierung Ihrer Netzwerkinfrastruktur.' , image: '/Netzwerktechnik.jpg' },
        { title: 'Planung und Beratung', description: 'Planung und Beratung für Elektroinstallationen in Neubauten, Umbauten und Sanierungen – mit CAD- und BIM-Technologie.', image: '/ElektroPlanung.jpg' },
        { title: 'Photovoltaik', description: 'Wir bieten Dienstleistungen für Planung, Installation und Wartung von Photovoltaikanlagen.', image: '/Photovoltaik.jpg' },
        { title: 'E-Tankstellen', description: 'Wir fördern die Elektromobilität durch benutzerfreundliche Lösungen.', image: '/electric-car-4381728_640.jpg'},
        { title: 'Sicherheitstechnik', description: 'Wir bieten zuverlässige Lösungen wie Alarmanlagen, Videoüberwachung, Zutrittskontrollen, Brandmeldeanlagen und sichere Kassensysteme.', image: '/Videotechnik.jpg' },
    ];

    return (
        <section className="service-cards">
            {services.map((service, index) => (
                <div
                    key={index}
                    className={`card ${service.title === 'E-Tankstellen' ? 'card-etankstellen' : service.title === 'Elektrotechnik' ? 'card-elektrotechnik' : service.title === 'Netzwerktechnik' ? 'card-netzwerktechnik' : service.title === 'Planung und Beratung' ? 'card-videotechnik' : service.title === 'Photovoltaik' ? 'card-photovoltaik' : service.title === 'Sicherheitstechnik' ? 'card-alarmanlagen' : ''}`}
                    style={service.image ? { backgroundImage: `url(${service.image})` } : {}}
                >
                    <h3>{service.title}</h3>
                    <p>{service.description}</p>
                </div>
            ))}
        </section>
    );
}

export default ServiceCards;
