import React from 'react';
import { Link } from 'react-router-dom';
import './StickyFooter.css';

function StickyFooter() {
    return (
        <div className="sticky-footer">
            <div className="left-side">
                <img src="/whitephone.png" alt="Phone Icon" className="icon" />
                <p><a href="tel:+4367763472758" className="phone-number1">Rufen Sie uns an</a></p>
            </div>
            <div className="separator"></div>
            <div className="right-side">
                <img src="/whitemessage.png" alt="Message Icon" className="icon" />
                <p><Link to="/contact" className="email-link">Schreiben Sie uns</Link></p>
            </div>
        </div>
    );
}

export default StickyFooter;
