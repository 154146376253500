import React from 'react';
import './Impressum.css';

function Impressum() {
    return (
        <div className="impressum">
            <h1>Impressum</h1>
            <p>
                <strong>Elektro-gelbstein GmbH</strong><br />
                Musterstraße 1<br />
                1010 Wien, Österreich
            </p>
            <p>
                <strong>Telefon:</strong> +43 660 123 456<br />
                <strong>E-Mail:</strong> office@gelbstein-elektrotechnik.at
            </p>
            <p>
                <strong>Geschäftsführer:</strong> Max Mustermann<br />
                <strong>Handelsregister:</strong> FN 123456a<br />
                <strong>UID-Nummer:</strong> ATU12345678
            </p>
            <p>
                <strong>Berufsbezeichnung:</strong> Elektrotechniker (verliehen in Österreich)<br />
                <strong>Aufsichtsbehörde:</strong> Magistratisches Bezirksamt für den 1. Bezirk<br />
                <strong>Mitglied der Wirtschaftskammer Wien, Fachgruppe Elektrotechnik</strong><br />
                <strong>Gewerbeordnung:</strong> <a href="https://www.ris.bka.gv.at">www.ris.bka.gv.at</a>
            </p>
            <p>
                <strong>Unternehmensgegenstand:</strong> Planung und Ausführung elektrotechnischer Anlagen
            </p>
            <p>
                <strong>Streitbeilegung:</strong> Die EU-Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
                <a href="http://ec.europa.eu/consumers/odr">http://ec.europa.eu/consumers/odr</a>.
                Wir sind bereit, an einem außergerichtlichen Schlichtungsverfahren teilzunehmen.
            </p>
            <p>© 2024 Elektro-gelbstein GmbH. Alle Rechte vorbehalten.</p>
        </div>
    );
}

export default Impressum;
