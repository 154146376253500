import React from 'react';
import './Footer.css';


function Footer() {
    return (
    <>
        <footer className="footer">
            <div className="footer-container">

                <div className="footer-section logo-section">
                    <img src="/Logo.jpeg" alt="Company Logo" className="footer-logo" />
                    <p>Herzlich willkommen bei Gelbstein Elektrotechnik – Ihrem zuverlässigen Partner für die Planung und Umsetzung von maßgeschneiderten elektrotechnischen Lösungen für Industrie-, Gewerbe- und Wohngebäude.</p>
                </div>

                <div className="footer-section links-section">
                    <ul>
                        <li><a href="/impressum">Impressum</a></li>
                        <li><a href="#about">Datenschutzerklärung</a></li>
                    </ul>
                </div>

                <div className="footer-section contact-section">
                    <h3>Adresse</h3>
                    <p>Musterstraße 1, 1010 Wien</p>
                    <p>+43 677 634 72758</p>
                    <p>office@gelbstein-elektrotechnik.at</p>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; 2024 Gelbstein Elektrotechnik. Alle Rechte vorbehalten.</p>
            </div>
        </footer>


        <div className="sticky-footer">
        <a href="tel:+4367763472758" style={{ color: 'white', textDecoration: 'none' }}>
            Rufen Sie gleich an
        </a>
        </div>
    </>
    );
}

export default Footer;
