import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Header from './components/Header';
import ServiceCards from './components/ServiceCards';
import Footer from './components/Footer';
import About from './components/About';
import Contact from "./components/Contact";
import Slideshow from "./components/Slideshow";
import Impressum from "./components/Impressum";
import StickyFooter from "./components/StickyFooter";

function App() {
    return (
        <Router>
            <div className="App">
                <Navbar/>
                <Routes>
                    <Route path="/" element={
                        <div id="home" className="main-content">
                            <Slideshow/>
                            <Header/>
                            <ServiceCards/>
                        </div>
                    }/>
                    <Route path="/about" element={<About/>}/>
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/impressum" element={<Impressum />} />
                </Routes>
                <Footer/>
                <StickyFooter/>
            </div>
        </Router>
    );
}

export default App;
